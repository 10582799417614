import React, { Component } from 'react';
class HBoxObj extends Component {
    swRutea = false
    constructor(props) {
        super(props);
        if (this.swRutea)console.log("Rutea,this ******************", this.swRutea, this)
        if (this.swRutea) console.log("txdata", this.data)

        this.data = props;
        this.regp = this.data.pattern || "^[a-zA-Z0-9]+$"
        this.condition = new RegExp(this.regp);
        this.state = {
            value: this.data.value,
            errors: {msg:""},
        };
        if (this.swRutea) console.log("Contructor id:", this.data.id, this.data.value)
    }
    componentWillUpdate(nextProps, nextState) {
        this.data = nextProps;
        //this.setState({value: this.data.value})  Entra en loop no puedo
        //console.log("componentWillUpdate HBoxObj",this.data)
    }

   
    //validaValor(): void;

}

export default HBoxObj;
