import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "modEcomerce/ApiEcomerce";
import './CheckOutDespOrder.css';
import HFormUser from "global/components/Page/Forms/HFormUser";

class CheckOutDatosOrder extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
    }
    checkOutOrder = () => {
        toastr.success("Have fun storming the castle!", "Harrys");


        if (!super.validaForm("FrDespDat")) return
        var stData = {
            txNombres: document.getElementById("txNombres").value
            , txApe: document.getElementById("txApellidos").value
            , txEmp: document.getElementById("txRut").value
            , cbPais: document.getElementById("cbPais").value
            , txDir1: document.getElementById("txDir1").value
            , txDir2: document.getElementById("txDir2").value
            , txCiudad: document.getElementById("txCiudad").value
            , txEMail: document.getElementById("txEMail").value
            , txComuna: document.getElementById("txComuna").value
            , txPostal: document.getElementById("txPostal").value
            , txPhone: document.getElementById("txCelular").value
            , txComenta: document.getElementById("txZZComenta").value
        }

        this.post(Api.checkOutDatoCompraDespSave, stData).then(data => {
            if (data.ok) this.props.navigate(Api.checkOutFpayRoute)
        })
    }
    render = () => {
        return (
            <div className="col-lg-5 mt-md-30px mt-lm-30px ">

                <div className="your-order-area">
                    <h3>Su pedido</h3>
                    <div className="your-order-wrap gray-bg-4">
                        <div className="your-order-product-info">
                            <div className="your-order-total">
                                <ul>
                                    <li className="order-total">Total</li>
                                    <li>${this.data.dataObj.regTotal[0].totalpagarformat}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="Place-order mt-25">
                        <a className="btn-hover" onClick={this.checkOutOrder}>Guardar Datos Envio</a>
                    </div>
                </div>
            </div>
        )
    }

}
export default WithNavigate(CheckOutDatosOrder);