import React, { Component } from 'react';
import HBoxMsg from "./HBoxMsg";
class HBoxSelect extends Component {
  constructor(props) {
    super(props);
    this.data = props;
    //this.value=this.data.value;
    //console.log(props.data.value)
    this.state = {
      value: "",
      errors: {},
    };
  }

  componentWillUpdate(nextProps, nextState) {
    this.data = nextProps;
  }
  handleChange = name => event => {
    //console.log("handleChangeCbSelect")
    let err = {};
    this.setState({ errors: err }, () => {
      if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
        this.setState({ submitted: true });
      }
    });
    this.setState({ value: event.target.value });
    if (this.data.onChange) this.data.onChange();
  };
  render = () => {
    if (this.data.option == null) {
      return (<div className="input-group mb-3">
        <label className="col-sm-3 control-label" >{this.data.label}</label>
        <label className="col-sm-3 control-label" ><font color="red">Sin Datos</font></label>
      </div>)
    }
    //                    onClick={this.data.onClick} 



    return (
    // <div className="input-group mb-3" style={{visibility:this.data.visibility}}>
    //   <label className="col-sm-2  col-form-label" >{this.data.label}llll</label>
      <div className={this.data.classCol || "form-group"}>
                <label className="labels" >{this.data.label}</label>

      <div className="col-sm-10">
        <select key={this.data.id}
          data-live-search-style="startsWith"
          data-live-search="true"
          id={this.data.id}
          name={this.data.name || this.data.id}
          value={this.state.value || this.data.value || ""}
          onChange={this.handleChange("value")}
          disabled={this.data.disabled || false}
          style={this.data.style || { width: "350px" }}
          title={this.data.title}
          required={this.data.required}
          className={this.data.className + " form-control required"}
          visibility={this.data.visibility}
        >
          <option value='-1'>Seleccione un Item</option>
          {this.data.option.map((row, index) => {
            return <option key={row.name} value={row.name}>{row.descripcion}</option>
          })}
        </select>
        {this.state.errors.username && <p>{this.state.errors.username}ss</p>}
      </div>
      <HBoxMsg msg={this.state.errors.msg} id={this.data.id} />
    </div>
    );
  }

}
export default HBoxSelect;