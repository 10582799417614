import React, { Component } from 'react';
import ShowLoading from "global/components/ShowLoading";
import H5BaseTodo from "../H5/H5BaseTodo";
import ApiAuth from "global/auth";
import ApiGlo from "global/ApiGlobal";
import HFormUser from "global/components/Page/Forms/HFormUser";
class HPreCursos extends H5BaseTodo{
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            data: [],
            loading: true,
        };
    }
    // componentDid
    componentDidMount() { this.readApi() }
    readApi = () => {
        this.setState({ loading: true });
        //this.post(ApiGlo.headerTopNav)
        //     .then(data => {
        //         //this.setState({ data: data, loading: false })
        //         this.setState({ loading: false })
        //         if (data.dataObj && data.dataObj.regEcoHost && data.dataObj.regEcoHost.titulo != null) {
        //             document.title = data.dataObj.regEcoHost.titulo
        //             //this.data.interFn(data)
        //             apiRuta.setStateData(this, "dataHeader", data)
        //         }
        //     })
        this.setState({ loading: false });
    }
    render() {
        if (this.state.loading) return (<ShowLoading />)
        //console.log("atr:", this.data)
        //console.log("atrObj:", this.data.regObj)
        return (
            <div id="serv_tarj" className="col-md-4">
                <div className="card text-center">
                    <div className="card-body">
                        <h4 className="card-title">
                            <strong>
                                <i className={"fas  fa-2x " + this.getValor("icono")} aria-hidden="true"></i>
                            </strong><p dangerouslySetInnerHTML={{ __html: this.getValor("titulo") }}></p>
                        </h4>
                        <p className="card-text" style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: this.getValor("texto") }}>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default HPreCursos;