import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import Card_tp01 from './shoping_card/Card_01_card'
import Card_tp02 from './shoping_card/Card_02_single'
import Card_tp03 from './shoping_card/Card_03_text'
import './ShoppingCard01.css'
class ShoppingCard01 extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        //console.log("productoData Shop01:",this.data)
        return (
            <div className="card_01 col-12">
                <div className="row" key='yuye2'>
                    <div className="col-12 py-3" key='yuye'>
                        <div className="row" key='yuye22'>
                            {this.data
                                && this.data.lProductos
                                && this.data.lProductos.map((producto, index) => {
                                    switch (producto.html_card_type){
                                        case 1 :
                                                return (<Card_tp01  
                                                    key={'kcard01_' + index}
                                                    heartClick={() => this.data.heartClick(producto.idmodelo)}
                                                    addCar={this.data.addCar}
                                                    producto={producto} />)
                                        case 2 :
                                                return (<Card_tp02 
                                                    key={'kcard02_' + index}
                                                    heartClick={() => this.data.heartClick(producto.idmodelo)}
                                                    addCar={this.data.addCar}
                                                    producto={producto} />)
                                        case 3 :
                                                return (<Card_tp03 
                                                    key={'kcard02_' + index}
                                                    heartClick={() => this.data.heartClick(producto.idmodelo)}
                                                    addCar={this.data.addCar}
                                                    producto={producto} />)

                                    }
                                })}
                        </div >
                    </div>
                </div>
            </div>
        )
    }

}
export default WithNavigate(ShoppingCard01);
