import React, { Component } from 'react';
import HBoxObjBase from "./HBoxObjBase";
import HBoxObj from './HBoxObj';
class HBoxTextCel extends HBoxObj {
    render = () => {
        return (<HBoxObjBase type="text"
            pattern={this.data.pattern || "^[0-9]{3,3}.[0-9]{3}.[0-9]{3}$"}
            minLength={this.data.minLength || 8}
            maxLength={this.data.maxLength || 11}
            placeholder={this.data.placeholder || "Ingrese Nro. Celular"}
            formato="999.999.999"
            {...this.props}
        />
        );
    }
}
export default HBoxTextCel;

/*

    handleChange = name => event => {
        console.log("handle id0:", this.data.id, this.state.value, this.data.value)
        if (event.target.value.length === 3) {
            event.target.value += '.';
        }
        else if (event.target.value.length === 7) {
            event.target.value += '.';
        }
        let err = {};

        if (event.target.value.length < this.data.minLength) {
            err.msg = "Mínimo " + this.data.minLength + " caracteres";
        }
        else
            if (event.target.value.length > this.data.maxLength) {
                err.msg = "Máximo " + this.data.maxLength + " caracteres";
            }
            else {
                const regp = this.data.pattern || this.pattern
                //console.log("regp" + regp)
                const condition = new RegExp(regp);
                let val = condition.test(event.target.value);
                if (!val) {
                    err.msg = "Formato 999-999-999 ";
                }
            }
        console.log("handle id1:", this.data.id, this.state.value, this.data.value, event.target.value)
        this.setState({ value: event.target.value });
        this.setState({ errors: err }, () => {
            if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
                this.setState({ submitted: true });
            }
        });

    };
*/
