import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "global/ApiGlobal";
class BrMeVertNiv extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }

    //<i className="fa fa-align-justify"></i>
    render = () => (
        <div className="col-lg-2">
            <div className="header-menu-vertical">
                <h4 className="menu-title"
                    style={{ backgroundColor: this.data.colorHeadLine, color: this.data.colorTextLine }}>
                    {this.data.categoList[0] && this.data.categoList[0].descripcion}
                </h4>
                <ul className="menu-content display-none">
                    {this.data.categoList && this.data.categoList.map((menuNiv00, index00) => {
                        if (menuNiv00.idpadre == this.data.categoList[0].idcatego && index00 > -1)
                            return (
                                <li key={'niv00' + index00} className="menu-item">
                                    <a onClick={() => this.props.navigate(Api.categoriaRoute + "?refCat=" + menuNiv00.idcatego)}
                                    >{menuNiv00.descripcion}
                                        {menuNiv00.hijos > 0 && (<i className="ion-ios-arrow-right"></i>)}
                                    </a>
                                    <ul className="sub-menu sub-menu-2">
                                        <li key={'niv11-1' + index00} >
                                            <ul className="submenu-item">
                                                {this.data.categoList.map((menuNiv11, index11) => {
                                                    if (menuNiv11.idpadre == menuNiv00.idcatego)
                                                        return (
                                                            <li key={'niv11-2' + index11} >
                                                                <a  onClick={() => this.props.navigate(Api.categoriaRoute + "?refCat=" + menuNiv11.idcatego)}
                                                                >{menuNiv11.descripcion}

                                                                    {menuNiv11.hijos > 0 && (<i className="ion-ios-arrow-right"></i>)}
                                                                </a>
                                                                <ul className="sub-menu sub-menu-2 sub-menu-3 ">
                                                                    <li key={'niv11-3' + index11} >
                                                                        <ul className="submenu-item">
                                                                            {this.data.categoList.map((menuNiv22, index22) => {
                                                                                if (menuNiv22.idpadre == menuNiv11.idcatego)
                                                                                    return (<li key={'niv11-4' + index22}>
                                                                                        <a  onClick={() => this.props.navigate(Api.categoriaRoute + "?refCat=" + menuNiv22.idcatego)}
                                                                                        >{menuNiv22.descripcion}
                                                                                        </a>
                                                                                    </li>)
                                                                            })}
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        )
                                                })}
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            )
                    })
                    }
                    {/*<li className="expand"><a href="javascript:;"><span><i className="ion-android-add"></i>More Categories99</span></a></li>*/}
                </ul>
            </div>
        </div>


    )
}
export default WithNavigate(BrMeVertNiv);