import React, { Component } from 'react';
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextRun from "global/components/Page/InputHtml/HBoxTextRun";
import HBoxTextMail from "global/components/Page/InputHtml/HBoxTextMail";
import HBoxTextCel from "global/components/Page/InputHtml/HBoxTextCel";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect"
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HFormUser from "global/components/Page/Forms/HFormUser";
import './CheckOutDespBilling.css';
class CheckOutDatosBilling extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
    }
    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;

        var stJs = ''
        stJs += '$(".checkout-despa").on("click", function(e) {'
        stJs += '    $(".open-despa").slideToggle(1000);'
        stJs += '});'

        stJs += '$(".checkout-retiro").on("click", function(e) {'
        stJs += '    $(".open-retiro").slideToggle(1000);'
        stJs += '});'

        stJs += '$(".checkout-cuenta").on("click", function(e) {'
        stJs += '    $(".open-cuenta").slideToggle(1000);'
        stJs += '});'
        s.innerHTML = stJs;
        this.instance.appendChild(s);
    }

    render = () => {
        return (
            <div className="col-lg-7">
                <HBoxForm id="FrDespDat">
                    <div ref={el => (this.instance = el)} />
                    <div className="billing-info-wrap">
                        <h3>Detalles de Entrega</h3>
                        <div className="different-address  mt-30px">

                            <div className="row">
                                <div className="col-lg-6">
                                    <HBoxTextRun
                                        label="Rut"
                                        id="txRut"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_empresa}
                                        maxLength="100"
                                        minLength="5"
                                        required
                                    />
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <HBoxTextMail
                                        label="Email"
                                        id="txEMail"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_email}
                                        maxLength="100"
                                        minLength="5"
                                        required
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <HBoxText
                                        label="Nombres"
                                        id="txNombres"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_nombres}
                                        maxLength="100"
                                        minLength="5"
                                        required="si"
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <HBoxText
                                        label="Apellidos"
                                        id="txApellidos"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_apellidos}
                                        maxLength="100"
                                        minLength="5"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="col-lg-12">
                            <input name="chEnvio" id="txChDesp" className="checkout-despa" type="radio" />
                            <label>&nbsp;  Despacho a Domicilio</label>
                        </div>


                        <div className="checkout-account-toggle open-despa mb-30">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="billing-select mb-20px">
                                        <HBoxSelect
                                            label="Pais"
                                            id="cbPais"
                                            value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_pais}
                                            option={[{ name: '1', descripcion: 'Chile' }]}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <HBoxText
                                        label="Ciudad"
                                        id="txCiudad"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_ciudad}
                                        maxLength="100"
                                        minLength="5"
                                        required
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <HBoxText
                                        label="Comuna"
                                        id="txComuna"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_comuna}
                                        maxLength="100"
                                        minLength="5"
                                        required
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <HBoxText
                                        label="Dirección"
                                        id="txDir1"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_dir1}
                                        maxLength="100"
                                        minLength="5"
                                        required
                                    />
                                    <HBoxText
                                        label="Dirección Adicional"
                                        id="txDir2"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_dir2}
                                        maxLength="100"
                                        minLength="5"
                                    />

                                </div>


                                <div className="col-lg-6 col-md-6">
                                    <HBoxText
                                        label="Código Postal"
                                        id="txPostal"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_postal}
                                        maxLength="100"
                                        minLength="5"
                                        required
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <HBoxTextCel
                                        label="Teléfono"
                                        id="txCelular"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_phone}
                                        maxLength="20"
                                        minLength="5"
                                        required
                                    />
                                </div>




                            </div>
                        </div>

                        <div className="checkout-retiroxx mb-30px">
                            <input name="chEnvio" id="txChRet" className="checkout-retiro" type="radio" />
                            <label>&nbsp;  Retiro en Tienda</label>
                        </div>

                        <div className="checkout-account-toggle open-retiro mb-30">
                            <button className="btn-hover checkout-btn" type="submit">Retiro en Tienda</button>
                        </div>



                        <div className="different-address  mt-30px">
                            <div className="additional-info-wrap">
                                <h4>Información Adicional</h4>
                                <div className="additional-info">
                                    <label>Comentarios</label>
                                    <textarea name="txZZComenta" id="txZZComenta"
                                        placeholder="Notas sobre su pedido, p. notas especiales para la entrega. "
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].env_zzcomenta}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </HBoxForm>
            </div>
        )
    }

}
export default CheckOutDatosBilling;