import React, { Component } from 'react';
class HBarraTabs extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    render = () => {
        return (<>
            <ul className="nav nav-pills user-profile-tab justify-content-end mt-2         bg-primary-subtle         rounded-2 rounded-top-0" id="pills-tab" role="tablist">
                {this.data.tabs && this.data.tabs.map((regRow, index) => {
                    return (<li className="nav-item" role="presentation" key={"iuei"+ index}>
                        <button className={"nav-link position-relative rounded-0 d-flex align-items-center justify-content-center bg-transparent fs-3 py-6 "
                            + (index == 0 ? " active" : "")}
                            id={"pills-" + regRow.name + "-tab"}
                            data-bs-target={"#pills-" + regRow.name}
                            data-bs-toggle="pill"
                            type="button"
                            role="tab"
                            aria-controls={"pills-" + regRow.name}
                            aria-selected={index == 0 ? "true" : "false"}>
                            <i className={"ti me-2 fs-6 " + (regRow.icon || ti - user - circle)}></i>
                            <span className="d-none d-md-block">{regRow.label}</span>
                        </button>
                    </li>)
                })}

            </ul>
            <div className="tab-content" 
                id="pills-tabContent"
                style={{marginTop:'10px'}}>
                {this.props.children}
            </div>
        </>
        );
    }

}
export default HBarraTabs;