import React, { Component } from 'react';
import HBoxHelp from "./HBoxHelp";
class HBoxTextCod extends Component {
    swRutea = true
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            obj: {
                valueid: this.data.valueid
                , descrip: this.data.descrip || ""
            }
        };
    }

    setObjeto = (objeto) => {
        if (this.swRutea) console.log("setObjeto***********************************:" )
        if (!objeto.valueid || !objeto.descrip){
            //console.log("No Exise valueid ***********")
            alert("Error el Objeto Help ( ==>" + this.data.sqlHelp +" ), no  tiene los campos valueid y/o descrip")
            // {Object.entries(objeto).map(([key, value]) => {
            //     console.log("key: ",key , " value:",value)
            //     if (key.startsWith("id")) objeto = {...objeto,valueid:value}
            //     if (key.startsWith("nombre") || key.startsWith("descrip")) 
            //         objeto = {...objeto,valueid:value}
            // })}
        }
        if (this.swRutea) console.log("setObjeto obj 00:", this.data.id, objeto )
        this.setState({ obj: objeto })
        //document.getElementById(this.data.id).value = this.state.obj.valueid + "/" + this.state.obj.descrip;
        //this.objCodigo.setValue(objObjeto.cc_value)
        //console.log("Hlp objObjetoSet:",objObjeto)
        // this.txIdComuna.setValue(objObjeto.nombre + "/UU")
        //this.data.onClickHelp(objObjeto)
        if (this.swRutea) console.log("setObjeto obj 11:", this.state.obj, " Objeto:", objeto)
    }
    getObjeto = () => {
        if (this.swRutea) console.log("getObjeto:", this.state.obj)
        return this.state.obj
    }
    getId = () => {
        console.log("getId obj:", this.state.obj)
        return this.state.obj.valueid
    }
    // componentWillUpdate(nextProps, nextState) { this.data = nextProps; }
    render = () => {
        return (<div className={this.data.classCol || "form-group"}>
            <label className="labels" >{this.data.label}</label>
            <div>
                <div className="input-group mb-3" style={this.data.styleDiv || {}}>
                    <input
                        id={this.data.id}
                        type={this.data.type || "text"}
                        // ref={this.listRef}
                        // onChange={this.handleChange("value").bind(this)}
                        formato={this.data.formato || ""}
                        value={this.state.obj.valueid + " / " + this.state.obj.descrip}
                        disabled={this.data.disabled || true}
                        name={this.data.name || this.data.id}
                        style={this.data.style}
                        title={this.data.title || this.data.label}
                        placeholder={this.data.placeholder || this.data.title || this.data.label}
                        pattern={this.data.pattern || "^[a-zA-Z0-9 áéíóúÁÉÍÓÚñ.-_,]+$"}
                        size={this.data.size || 10}
                        minLength={this.data.minLength || 0}
                        maxLength={this.data.maxLength || 1}
                        className={this.data.className + " form-control required"}
                        required={this.data.required || false}
                    />
                    {this.props.children}
                    {this.data.btIcon && (<div className="input-group-append">
                        <div className="input-group-text">
                            <span className="fas fa-envelope"></span>
                        </div>
                    </div>)}

                    {this.data.sqlHelp && (<HBoxHelp
                        sqlHelp={this.data.sqlHelp}
                        onClickHelp={this.data.onClickHelp}
                    />)}
                </div>
                {this.state.errors && this.state.errors.msg && <HBoxMsg msg={this.state.errors.msg} id={this.data.id} />}

            </div>
        </div>
        )
    }


}
export default HBoxTextCod;

    // renderXX = () => {
    //     console.log("textCod render data:", this.data, " obj", this.state.obj)
    //     // value={this.state.obj.valueid + "/" + this.state.obj.descrip }
    //     return (
    //         <HBoxObjBase
    //             {...this.props}
    //             value={this.state.valueid + "/ww:" + this.state.descrip}
    //             ref={x => this.objCodigo = x}
    //             size={this.data.size || 10}
    //             minLength={this.data.minLength || 1}
    //             maxLength={this.data.maxLength || 400}
    //             sqlHelp={this.data.sqlHelp}
    //             onClickHelp={this.data.onClickHelp || this.setObjeto}
    //         />

    //     );
    // }