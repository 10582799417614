/*cd /home/ec2-user/docker/oficial/nodejs/FrGlobal/context/user
 head.append('Access-Control-Allow-Origin', '*')

        head.append('Cache-Control','no-cache, no-store, must-revalidate');
        head.append('Pragma',' no-cache');
        head.append('Expires','0');
docker restart front-doc-docente
*/

import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import funMsg from "global/utils/funMsg";
import funVar from "global/utils/funVar";
    const headers = () => {
        let head = new Headers();
        head.append('Content-Type', 'application/json')
        head.append('Access-Control-Allow-Origin', '*')
        head.append('Cache-Control','no-cache, no-store, must-revalidate');
        head.append('Pragma',' no-cache');
        head.append('Expires','0');
        head.append('mode', 'no-cors')
        const session = {
            email: localStorage.getItem('login_email'),
            token: localStorage.getItem('login_token'),
            tokenBrowser: localStorage.getItem('login_browser'),
            tokenTbkId: localStorage.getItem('login_tbkid'),
        }
        if (session.email && session.token) {
            //console.log("session.Header>>>>>>" ,session.token)
            head.append('email', session.email);
            head.append('x-token', session.token);
            head.append('system', funVar.getSystem() );
        }
        if (session.tokenBrowser) {
            head.append('x-browser', session.tokenBrowser);
        }
        if (session.tokenTbkId) {
            head.append('x-tbkid', session.tokenTbkId);
        }
        let stPath = window.location.pathname
        head.append('front-path', stPath);
        //console.log("head ===========>",head)
        return head;
    }
    //https://www.saurabhmisra.dev/file-uploads-nodejs/
    const reqUpLoadImg64 = async (stFileObj, path, dataInput) => {
        if (stFileObj === "") stFileObj = "attachment"
        let url = `${funVar.getUrlApi()}${path}`;
        const data = new FormData()
        //var dataFile = document.querySelector('#attachment');
        var dataFile = document.querySelector('#' + stFileObj);
        //console.log("EnviandoFile:", stFileObj, dataFile.files[0])
        const file = dataFile.files[0]
        const data64 = await toBase64(file)

        // console.log("getImg64", data64)
        // console.log("dataInput", JSON.stringify(dataInput))

        data.append('attachment', data64)
        data.append('datamsg', JSON.stringify(dataInput))
        //const options = {method:'POST',headers:headers()};
        //console.log("=========>" ,url,options)
        const session = {
            email: localStorage.getItem('login_email'),
            token: localStorage.getItem('login_token'),
            tokenBrowser: localStorage.getItem('login_browser'),
            tokenTbkId: localStorage.getItem('login_tbkid'),
        }

        const res = await axios.post(url, data, {
            headers: {
                Accept: 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-token': session.token
                , 'x-browser': session.tokenBrowser
                , 'x-data': dataInput
            }
        })
        return res;
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    const reqUpLoadFile = async (stFileObj, path, dataInput) => {
        if (stFileObj === "") stFileObj = "attachment"
        let url = `${funVar.getUrlApi()}${path}`;
        const data = new FormData()
        //var dataFile = document.querySelector('#attachment');
        var dataFile = document.querySelector('#' + stFileObj);
        //console.log("EnviandoFile:",stFileObj,dataFile.files[0])
        const file = dataFile.files[0]
        data.append('attachment', dataFile.files[0])
        //console.log("dataInput",JSON.stringify(dataInput))
        data.append('datamsg', JSON.stringify(dataInput))
        //const options = {method:'POST',headers:headers()};
        //console.log("=========>" ,url,options)
        const session = {
            email: localStorage.getItem('login_email'),
            token: localStorage.getItem('login_token'),
            tokenBrowser: localStorage.getItem('login_browser'),
            tokenTbkId: localStorage.getItem('login_tbkid'),
        }

        const res = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
                , 'x-token': session.token
                , 'x-browser': session.tokenBrowser
                , 'x-data': dataInput
            }
        })
        return res;
    }
    const request = (form,method, path, body) => {
        if (path == null) {
            alert("No existe PATH definido en la API, (request)  :" + path + ": body : " + body)
            return new Promise((resolve, reject) => {
                return {
                    ok: true, msg: "Error En Respuesta Servidor "
                        + "  <br/>\r\n" + "err"
                        + "  <br/>\n" + "url"
                        + "  <br/>\n" + "options"
                }
            })
        }

        let url = `${funVar.getUrlApi()}${path}`;
        if (path.startsWith("/help")) url = `${funVar.getUrlHelp()}${path.substring(5)}`;
        else if (path.startsWith("/auth")) url = `${funVar.getUrlAuth()}${path.substring(5)}`;
        else if (path.startsWith("/admin")) url = `${funVar.getUrlAdmin()}${path.substring(6)}`;
        else if (path.startsWith("/global")) url = `${funVar.getUrlAuth()}${path}`;
        else if (path.startsWith("$/")) url = `${funVar.getUrlAuth()}${path.substring(2)}`;
        // else if (path.startsWith("/$"))
        // 	 url = `${funVar.getUrlAuth()}${path.substring(2)}`;
        const options = { method, headers: headers() };
        body.param.system = funVar.getSystem() 
        /*if (body)*/ options.body = JSON.stringify(body);

        return fetch(url, options)
            .then(async response => {
                //console.log("response.headers Fetch", form,response.headers, response)
                const token = response.headers.get("x-token")
                const xbrowser = response.headers.get("x-browser")
                const xtbkid = response.headers.get("x-tbkid")

                if (token && form.varToken != token){
                    localStorage.setItem('login_token', token)
                    form.varToken = token
                } 
                if (xbrowser && form.varXbrowser != xbrowser){
                    localStorage.setItem('login_browser', xbrowser)
                    form.varXbrowser = xbrowser
                } 
                if (xtbkid && form.varXtbkid != xtbkid) {
                    //console.log("Camvbiando login_tbkid ******",form.varXtbkid, xtbkid)
                    localStorage.setItem('login_tbkid', xtbkid)
                    form.varXtbkid = xtbkid
                }
                //console.log(token,xbrowser,xtbkid)
                //console.log(form.varToken,form.varXbrowser,form.varXtbkid)
                if (token ){
                    const decoded = jwtDecode(token);
                    //console.log("token decoded",decoded)
                    form.varUser.idUser=decoded._id
                    form.varUser.nroEmp=decoded.nro_empresa
                    form.setStateGlb()
                }
                const bodyResp = await response.json()
                
                
                


                //console.log("Request xbrowser==>",url,token,xbrowser)
                //console.log("bodyResp:",bodyResp)
                if (!bodyResp.ok) {
                    /*
                    //$("#primary-header-modal").modal('show');
                    funMsg.msgFetchErr(bodyResp.msg,"Error en Respuesta del Servidor")
                    //alert(bodyResp.msg)
                    //window.location.replace('/auth/signin');
                    */
                }
                return bodyResp
            })
            .catch(err => {
                //console.log("Request Err==>", err)
                funMsg.msgFetchErr(err,"Error Sin Respuesta")
                return {
                    ok: false, msg: "Error Catch En Respuesta Servidor "
                        + "  <br/>\r\n" + err
                        + "  <br/>\n" + url
                        + "  <br/>\n" + options
                };
            });
    }
export {request,
    reqUpLoadFile,
    reqUpLoadImg64}
