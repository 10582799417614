import React, { Component } from 'react';
import ApiTbk from "global_tbk/ApiTbk";
import Api from "modEcomerce/ApiEcomerce";
import ShowLoading from "global/components/ShowLoading";
import HFormUser from "global/components/Page/Forms/HFormUser";
class TbkPago extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            data: [],
            loading: true,
        };
    }
    addCarr(idProducto) {
        this.post(Api.addCarro, { 'idKey': idProducto })
            .then(data => {//this.stateHarrysRow(this,data)
            })
    }

    componentDidMount() {
        const queryParameters = new URLSearchParams(window.location.search)
        const token_ws = queryParameters.get("token_ws")
        //console.log("token_ws",token_ws)
        this.readApi(token_ws)
    }
    readApi = (token_ws) => {
        //http://api-emp.mispymes.cl/api/mod_ecomerce/mod_tbk/free/tbkPago?token_ws=01ab982359aee39d55e841e3ac8cbef758607a288d5cc61bcd12982c7ec40fcb
        //http://demo-herramientas.mispymes.cl/mod_tbk/tbkPago?token_ws=01ab2e3bafc9b1c68dd70a81ca5aabfa3b01a97b723c78e7b88a8a7ed6af3434

        this.post(ApiTbk.tbkPago, { 'token_ws': token_ws })
            .then(data => { this.setState({ data: data, loading: false }); 
            console.log("dataTNBK=>",data,data.dataObj,typeof(data.dataObj.status))
            if (data.dataObj.status  && data.dataObj.status== "AUTHORIZED"){
                console.log("Aprobado")
                this.tokenTbkNew()
                //this.addCarr(-999999)
            }
        })
    }
    render = () => {
        if (this.state.loading) return (<ShowLoading />)
        return (
            <div className="col-12">
                <div className="all-product-grid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-7">
                                <div id="checkout_wizard" className="checkout accordion left-chck145">
                                    <div className="checkout-step">
                                        <div className="pdpt-title">
                                            <h4>Resumen de Compra</h4>
                                        </div>

                                        <div className="cart-main-area mb-60px">
                                            <div className="container1">
                                                <div className="container-inner">
                                                    <h3 className="cart-page-title">Tus Productos del Carrito</h3>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <form action="#">
                                                                <div className="table-content table-responsive cart-table-content">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Imagen</th>
                                                                                <th>Nombre</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr><td className="product-name"><a href="#">VCI</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.vci}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">Monto</a></td>
                                                                                <td className="product-price-cart"><span className="amount">${this.state.data.dataObj.amount}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">Estatus</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.status}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">Orden</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.buy_order}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">Session</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.session_id}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">card_detail</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.card_detail && this.state.data.dataObj.card_detail.card_number}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">accounting_date</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.accounting_date}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">transaction_date</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.transaction_date}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">authorization_code</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.authorization_code}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">payment_type_code</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.payment_type_code}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">response_code</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.response_code}</span></td>
                                                                            </tr>
                                                                            <tr><td className="product-name"><a href="#">installments_number</a></td>
                                                                                <td className="product-price-cart"><span className="amount">{this.state.data.dataObj.installments_number}</span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="cart-shiping-update-wrapper">
                                                                            <div className="cart-shiping-update">
                                                                                <a onClick={() => { window.location.href = Api.comprarRoute }} >Continuar Comprando</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default TbkPago;


