import React, { Component } from 'react';
import HBoxObjBase from "./HBoxObjBase";
import HBoxObj from './HBoxObj';
class HBoxTextRun extends HBoxObj {
      render = () => {
        return (<HBoxObjBase type="text"
            pattern={this.data.pattern || "^[0-9]{2,3}.[0-9]{3}.[0-9]{3}-[0-9K]{1}$"}
            maxLength={this.data.maxLength || 20}
            placeholder={this.data.placeholder || "Ingrese Rut"}
            formato="999.999.999-9"
            {...this.props}
        />
        );
    }
}
export default HBoxTextRun;
/*
 
    validaValor = (valor) => {
        console.log("********* tecla", this.swRutea)
        if (valor.length === 2) {
            valor += '.';
        }
        else if (valor.length === 6) {
            valor += '.';
        }
        else if (valor.length === 10) {
            valor += '-';
        }
        let err = {};

        if (valor.length < this.data.minLength) {
            err.msg = "Mínimo " + this.data.minLength + " caracteres";
        }
        else
            if (valor.length > this.data.maxLength) {
                err.msg = "Máximo " + this.data.maxLength + " caracteres";
            }
            else {
                const regp = this.data.pattern || this.pattern
                console.log("regp" + regp)
                const condition = new RegExp(regp);
                let val = condition.test(valor);
                if (!val) {
                    err.msg = "Carácteres no válidos !";
                }
            }

        this.setState({ value: valor });
        this.setState({ errors: err }, () => {
            if (Object.getOwnPropertyNames(this.state.errors).length === 0) {
                this.setState({ submitted: true });
            }
        });

    };
*/