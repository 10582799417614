import React, { Component } from 'react';
import HBoxObjBase from "./HBoxObjBase";
import HBoxObj from './HBoxObj';
class HTextBox extends HBoxObj {
   
   
    //{this.renderTableData(this.data.titulos,this.data.rows,this.data.rqEdit,this.data.rqIns,this.data.rqUpd)}

    render = () => {
        return (<HBoxObjBase 
            type="text"
            defaultValue="Search..."
            pattern={this.data.pattern || "^[a-zA-Z0-9 áéíóúÁÉÍÓÚñ.-_,]+$"}
            maxLength={this.data.maxLength || 50}
            {...this.props}
        />
        );
    }

}
export default HTextBox;