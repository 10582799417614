import React, { Component } from 'react';
class HBoxMsg extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }
    componentWillUpdate(nextProps, nextState) {
        this.data = nextProps;
    }
    /*        <font color="red"><b>            {this.data.msg &&                <p>{this.data.msg}</p>            }</b>        </font>*/
    render = () => {
        return (<h5 id={"msg_" +this.data.id}style={{color: "red"}}>{this.data.msg}</h5>);
    }
}
export default HBoxMsg;