import React, { Component } from 'react';
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextRun from "global/components/Page/InputHtml/HBoxTextRun";
import HBoxTextMail from "global/components/Page/InputHtml/HBoxTextMail";
import HBoxTextCel from "global/components/Page/InputHtml/HBoxTextCel";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect"
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HFormUser from "global/components/Page/Forms/HFormUser";
import './CheckOutDatosBilling.css';
class CheckOutDatosBilling extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
    }

    render = () => {
        return (
            <div className="col-lg-7">
                <HBoxForm id="FrOrdenDat">
                    <div ref={el => (this.instance = el)} />
                    <div className="billing-info-wrap card-bodyxx">
                        <h3>Detalles de Facturación</h3>
                        <div className="row">
                            <div className="col-lg-6">
                                <HBoxTextRun
                                    label="Rut"
                                    id="txRut"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_empresa}
                                    maxLength="100"
                                    minLength="5"
                                    required
                                />
                            </div>
                            <div className="col-lg-6">
                                <HBoxTextMail
                                    label="Email"
                                    id="txEMail"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_email}
                                    maxLength="100"
                                    minLength="5"
                                    required
                                />
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <HBoxText
                                    label="Nombres"
                                    id="txNombres"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_nombres}
                                    maxLength="100"
                                    minLength="5"
                                    required="si"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <HBoxText
                                    label="Apellidos"
                                    id="txApellidos"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_apellidos}
                                    maxLength="100"
                                    minLength="5"
                                    required
                                />
                            </div>

                            <div className="col-lg-6">
                                <div className="billing-select mb-20px">
                                    <HBoxSelect
                                        label="Pais"
                                        id="cbPais"
                                        value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_pais}
                                        option={[{ name: '1', descripcion: 'Chile' }]}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <HBoxText
                                    label="Ciudad"
                                    id="txCiudad"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_ciudad}
                                    maxLength="100"
                                    minLength="5"
                                    required
                                />
                            </div>
                            <div className="col-lg-6">
                                <HBoxText
                                    label="Comuna"
                                    id="txComuna"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_comuna}
                                    maxLength="100"
                                    minLength="5"
                                    required
                                />
                            </div>

                            <div className="col-lg-6">
                                <HBoxText
                                    label="Dirección"
                                    id="txDir1"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_dir1}
                                    maxLength="100"
                                    minLength="5"
                                    required
                                />
                            </div>
                            <div className="col-lg-6">
                                <HBoxText
                                    label="Dirección Adicional"
                                    id="txDir2"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_dir2}
                                    maxLength="100"
                                    minLength="5"
                                />
                            </div>
                            <div className="col-lg-6">
                                <HBoxText
                                    label="Código Postal"
                                    id="txPostal"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_postal}
                                    maxLength="100"
                                    minLength="5"
                                    required
                                />
                            </div>
                            <div className="col-lg-6">
                                <HBoxTextCel
                                    label="Teléfono"
                                    id="txCelular"
                                    value={this.data.dataObj.regOrden[0] && this.data.dataObj.regOrden[0].ord_phone}
                                    maxLength="20"
                                    minLength="5"
                                    required
                                />
                            </div>
                        </div>


                    </div>
                </HBoxForm>
            </div>
        )
    }

}
export default CheckOutDatosBilling;