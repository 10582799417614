import React, { Component } from 'react';
class HBoxForm extends Component {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            value: "",
            errors: {},
        };
    }
    componentWillUpdate(nextProps, nextState) {
        //componentDidUpdate(nextProps, nextState){
        this.data = nextProps;
    }

    //action="void(0)"

    render = () => {
        return (
            <div className="x_content">
                <form className="form-horizontal form-label-left"
                    id={this.data.id || "forms-registro"}
                >
                    <div className="card-bodyx" style={{ width: '100%' }}>
                        {this.props.children}
                    </div>
                </form>
            </div>
        );
    }

}
export default HBoxForm;