import HBoxtObj from "./HBoxObj";
import HBoxObjBase from "./HBoxObjBase";

class HBoxTextMail extends HBoxtObj{
 
     render = () => {
        return (<HBoxObjBase type="text"
            pattern={this.data.pattern || "^[_A-Za-z0-9-+]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})$"}
            minLength={this.data.minLength || 8}
            maxLength={this.data.maxLength || 11}
            placeholder={this.data.placeholder || "Ingrese Email"}
            formato="xx@xx.cl"
            {...this.props}
        />
        );
    }
}    
export default  HBoxTextMail;
/*
   componentDidMount = () => { 
        $(function (e) {
            "use strict";
            $(".email-inputmask").inputmask({
                mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[*{2,6}][*{1,2}].*{1,}[.*{2,6}][.*{1,2}]",
                greedy: !1,
                onBeforePaste: function (n, a) {
                    return (e = e.toLowerCase()).replace("mailto:", "");
                },
                definitions: {
                    "*": {
                        validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~/-]",
                        cardinality: 1,
                        casing: "lower",
                    },
                },
            })
            ;
        })
     }
*/            