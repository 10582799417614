import { Component } from 'react';
import UserContext from 'global/context/user/UserContext';
import apiRuta from "global/utils/funRuta";
import apiGl from "global/ApiGlobal";

class HFormUser extends Component {
    swRutea=true
    //static contextType = UserContext;
    constructor(props) {
        super(props);
        //this.userVar = this.context;
        this.data = props;
        this.state = {
            data: [],
            resObj: [],
            loading: true,
            loadGr: true,
            swError: false,
        };
    }
    // componentDidMount() {this.userVar = this.context;}
    // componentDidUpdate() {this.userVar = this.context;}
    // componentWillUnmount() {this.userVar = this.context;}
    // render() {this.userVar = this.context;}
    tokenTbkNew=()=>{
        alert(22229)
        localStorage.removeItem("login_tbkid")
        apiRuta.setStateGlb(this)
        //alert(22230)
    }

    addMenu(_menu) {
        if(1==1) return
        _menu.arMenu.push({
            titulo: "Sessión"
            , icon: "fa fa-bug"
            , menNiv: [
                {
                    titulo: "Cerrar Session"
                    , icon: "fa fa-bug"
                    , ruta: ApiGl.singOutRoute
                }
            ]
        }
            , {
                titulo: "FrameWork"
                , icon: "fa fa-bug"
                , menNiv: [
                    { titulo: "AdminLte", ruta: "/index_AdLte.html", icon: "fa fa-bug" }
                    , { titulo: "Architec", ruta: "/index_AdArchi.html", icon: "fa fa-bug" }
                    , { titulo: "Aero", ruta: "/index.html", icon: "fa fa-bug" }
                ]
            })
    }
    //componentDidMount() {this.usuarioVar = this.context; }
    reDirect = (objData) => {
        //console.log("objData",objData)
        if (objData.reDirect) apiRuta.toRutaAdd(this, "", objData.reDirect, null)
    }
    saltarRutaContext = (form, iLin) => {
        //console.log("saltacontext:",form)
        apiRuta.toRutaPop(form, iLin)
    }
    saltarRuta = (iLin) => {
        //console.log("salta:",this.data,this.data.contexto)
        apiRuta.toRutaPop(this.data.contexto, iLin)
    }
    saltarVolverContext = (form) => {
        //console.log("Volver:",this.data,this.data.contexto)
        apiRuta.toRutaBack(form)
    }
    saltarVolver = () => {
        //console.log("Volver:",this.data,this.data.contexto)
        apiRuta.toRutaBack(this.data.contexto)
    }
    msgFormError() {
        alert("Corriga los <b>Errores Informados</b> ")
        return
        //alert("Corriga los Errores")
        Swal.fire({
            showCloseButton: true,
             title: "<strong><font color='red'>Error</font></strong>",
            // text: "<strong><u>Error</u></strong>Corriga los Errores informados",
            confirmButtonText: '<i class="ti ti-thumb-up"></i> OK',
            type: "warning",  //"warning","info",
            showConfirmButton: true,
            timer: 3500,
            //position: "top-end",
            // footer: "<a href>Why do I have this issue?</a>",
            html:
            "Corriga los <b>Errores Informados</b> ",
            // imageUrl: "../assets/images/profile/user-2.jpg",
            // showCancelButton: true,
            // confirmButtonColor: "#DD6B55",
            // confirmButtonText: "Yes, delete it!",
            // closeOnConfirm: false,
            // showCloseButton: true,
            // focusConfirm: false,
            // confirmButtonText: '<i class="ti ti-thumb-up"></i> Great!',
            // confirmButtonAriaLabel: "Thumbs up, great!",
            // cancelButtonText: '<i class="ti ti-thumb-down"></i>',
            // cancelButtonAriaLabel: "Thumbs down",
            // animation: false,
            // customClass: {
            //   popup: "animated tada",
            // },
  
        }
        // // Funcion que se ejecuta despues del boton
        // ,function () {
        //   swal("Deleted!", "Your imaginary file has been deleted.", "success");
        // }).then((result) => {
        //     if (result.value) {
        //       Swal.fire("Deleted!", "Your file has been deleted.", "success");
        //     }
        //   }
        );

    }
    validaForm(stIdForm) {
        if (!this.revisaCampos(stIdForm)) {
            this.msgFormError()
            return false
        }
        return true
    }
    upLoadImg64(stFileObj, path, data) { return this.context.dataUser.upLoadImg64(stFileObj, path, data) }
    upLoadFile (stFileObj, path, data) { return this.context.dataUser.upLoadFile (stFileObj, path, data) }
    post(stUrl, pParam) { return this.context.dataUser.post(stUrl, pParam) }
    stateHarrys(form,pData) { return this.context.dataUser.stateHarrys(form, pData) }
    //myContext() { this.usuarioVar = this.context }
    //https://www.geeksforgeeks.org/form-validation-using-jquery/
    revisaCampos(stIdForm) {
        let swValido=true
        let stFormName = "forms-registro"
        //console.log("validaForm")
        let dataErr = [];
        //"#data--json > input"
        if (stIdForm) stFormName = stIdForm
        //const formElem = document.querySelectorAll("");
        const form = document.getElementById(stFormName)
        if (!form) {
            alert("No Existe Formulario : " + stFormName)
            return false
        }
        const formInput = form.querySelectorAll("input");
        for (let i = 0; i < formInput.length; i++) {
            const objEle = formInput[i]
            const key = objEle.getAttribute("id");
            $("#msg_"+ key).hide();
            if (!objEle.disabled && key != null) {
                //console.log("obj",key,objEle)
                const chValid = objEle.checkValidity();
                const keyPat = objEle.getAttribute("pattern");
                const keyTyp = objEle.getAttribute("type");
                const keyVal = objEle.getAttribute("value");
                const keyReq = objEle.getAttribute("required");
                const keyMin = objEle.getAttribute("minLength");
                const keyMax = objEle.getAttribute("maxLength");
                if (this.swRutea ) console.log("keyText:", key,keyReq,"chValid:" + chValid, keyPat, keyMin,keyMax,"val:" + keyVal,keyTyp)
                if (keyTyp != "radio"  && keyTyp != "checkbox" && 
                    keyReq != null && (keyMin != null || keyMax !=null ) ){
                    if (keyReq != null && (keyVal + "").trim() == "") {
                            swValido =  false;
                            dataErr.push({campo:key,msg:"Error, Campo Vacío"})
                    }
                    else if (keyReq !=null || (keyVal + "").trim() != "" ) {
                        if (keyPat != null) {
                            this.condition = new RegExp(keyPat);
                            let valTest = this.condition.test(keyVal);
                            if (!valTest) {
                                dataErr.push({campo:key,msg:"RegExp",regexp:keyPat})
                                swValido =  false;
                            }   
                        }
                        if ((keyVal + "").length < keyMin || (keyVal + "").length > keyMax) {
                            dataErr.push({campo:key,msg:"Cantidad de letras menor " + keyMin + ".." + keyMax})
                            swValido= false
                        }
    
                        //const elem = formInput[i];
                        //data[`${key}`] = elem.value;
                    }
                }    
            }
        }
        const formSel = form.querySelectorAll("select");
        for (let i = 0; i < formSel.length; i++) {
            const objEle = formSel[i]
            const key = objEle.getAttribute("id");
            const keyReq = objEle.getAttribute("required");
            $("#msg_"+ key).hide();
            if (this.swRutea )console.log("keySel:", key, objEle, objEle.required, keyReq, objEle.selectedIndex, objEle.value)
            if (objEle.required && objEle.value == -1){
                dataErr.push({campo:key,msg:"Error, Seleecione un ítem"})
                swValido= false
            } 
        }
        if (this.swRutea ) console.log("Error********************************************")
        for (let i = 0; i < dataErr.length; i++) {
            if (this.swRutea ) console.log("keyMsgError:",dataErr[i].campo)
            const keyMsg = "msg_" + dataErr[i].campo
            //$(keyMsg).hide();
            $("#" + keyMsg).show();
            $("#"+keyMsg).html(dataErr[i].msg);
            }

        if (this.swRutea)console.log("errores",swValido,dataErr)
        return swValido;
    }
    btVolver = () => {apiRuta.toRutaBack(this)}
}
HFormUser.contextType = UserContext;
export default HFormUser;