import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiGl from "global/ApiGlobal";
import ApiAuth from "global/auth";
import "./Barra/BrMeVertNiv.css"
import "./Barra/BrHeadSearch.css"




//{!this.data && <Pag404EcoHost/>}
/*
import BarraStart from "global/components/Page/Header/Barra/BarraNav01Start";
import BarraStartMob from "global/components/Page/Header/Barra/BarraNav01StartMob";

import BarraTop from "global/components/Page/Header/Barra/BarraNav02Top";
import BarraTopCarro from "global/components/Page/Header/Barra/BarraNav02TopCarro";

import BarraMenVer from "global/components/Page/Header/Barra/BarraNav03Men";
import BarraMenuHeadMob from "global/components/Page/Header/Barra/BarraNav02TopMob";
import BarraMenuCateMob from "global/components/Page/Header/Barra/BarraNav03MenuCateMob";
*/

import BrHeadMenu from "./Barra/BrHeadMenu";

import BrMeVertNiv from "./Barra/BrMeVertNiv";
import BrMeVertPag from "./Barra/BrMeVertPage";


class HFrBarraMenu extends Component {
    constructor(props) {
        super(props);
        this.data = props;
    }

    componentDidMount() {
        if (this.data.dataObj.regCatego.length == 0) return;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        var stJs = '   $(".header-menu-vertical .menu-title").on("click", function(event) {            $(".header-menu-vertical .menu-content").slideToggle(500);        });'
        stJs += '$(".menu-content").each(function() {'
        stJs += 'var $ul = $(this),'
        stJs += '        $lis = $ul.find(".menu-item:gt(8)"),'
        stJs += '        isExpanded = $ul.hasClass("expanded");'
        stJs += '    $lis[isExpanded ? "show" : "hide"]();'

        stJs += '    if ($lis.length > 0) {'
        stJs += '        $ul.append('
        stJs += '            $('
        stJs += '                \'<li key="1" className="expand">\' +'
        stJs += '                   (isExpanded ? \'<a href="javascript:;"><span><i className="ion-android-remove"></i>Close Categories</span></a>\' : \'<a href="javascript:;"><span><i className="ion-android-add"></i>More Categories</span></a>\') +'
        stJs += '                "</li>"'
        stJs += '            ).on("click", function(event) {'
        stJs += '                var isExpanded = $ul.hasClass("expanded");'
        stJs += '                event.preventDefault();'
        stJs += '                $(this).html(isExpanded ? \'<a href="javascript:;"><span><i className="ion-android-add"></i>More Categories</span></a>\' : \'<a href="javascript:;"><span><i className="ion-android-remove"></i>Close Categories</span></a>\');'
        stJs += '                $ul.toggleClass("expanded");'
        stJs += '                $lis.toggle(300);'
        stJs += '            })'
        stJs += '        );'
        stJs += '    }'
        stJs += '});'
        s.innerHTML = stJs;
        this.instance.appendChild(s);
    }
    render = () => {
        //console.log("BarraMenu", this.data)
        return (
            <div className="header-menu bg-red sticky-nav d-lg-block d-none padding-0px menu_fixed animated fadeInDown"
                style={{ backgroundColor: this.data.regBar.html_bgcolor }}>
                <div ref={el => (this.instance = el)} />
                <div className="container1 col-lg-12" key="u7u">
                    <div className="row col-lg-12" style={{ color: this.data.regBar.html_txcolor }}>
                        {this.data.regBar.items.length ==0 && (<>&nbsp;</>)}
                        {/* <div className="col-lg-9" style={{ color: this.data.regBar.html_txcolor }} > */}

                            {this.data.regBar.items.map((regBarItem, index) => {
                                switch (regBarItem.idbaritem) {
                                    case 1: // Logo de la Empresa
                                        return this.logoEmpresa(regBarItem, index)
                                    case 2:// Barra de Search
                                        return this.barraSearch(regBarItem, index)
                                    case 3:// 
                                        return this.menuCategoria(regBarItem, index)
                                    case 4:// Menu Login
                                        return this.menuLogin(regBarItem, index)
                                    case 5:// Telefono  => ValText
                                        return this.telefono(regBarItem, index)
                                    case 6:         // Wasap Web  => ValText
                                        return this.whatsap(regBarItem, index)
                                    case 7: // Carrito
                                        return this.carrito(regBarItem, index)
                                    case 8:          // Corazones
                                        return this.corazones(regBarItem, index)
                                }
                            })}
                            {/* <div className="col-lg-9" style={{ color: this.data.regBar.html_txcolor }} > */}
                            {/* </div> */}

                        {/* </div> */}
                    </div>
                </div>
            </div>
        )
    }
    logoEmpresa(regBarItem, index) {
        return (<div className="col-md-1 d-flex" key={"menKu1_" + index}>
            <div className="logo align-self-center">
                <a onClick={() => this.props.navigate("/")}>
                    <img className="img-responsive"
                        src={process.env.REACT_APP_URL_DATA + "/"
                            + ApiAuth.getEmpresa()
                            + "/img/" + regBarItem.valtext}
                        alt="logo.png"
                        width="50px" height="50px"
                    /></a>
            </div>
        </div>)
    }
    barraSearch(regBarItem, index) {
        return (<div className="col-md-3 align-self-center" key={"menKu2_" + index}>
            <div className="header-right-element d-flex justify-content-between">
                <div className="search-element media-body me-20px">
                    <form className="d-flex" action={this.data.pDataBarra.pSearchRoute}>
                        <input type="text" placeholder="Busqueda Producto ..." />
                        <button><i className="icon-search"></i></button>
                    </form>
                </div>

            </div>
        </div>)
    }
    menuCategoria(regBarItem, index) {
        switch (regBarItem.valnum) {
            case 1:
                return (<div className="col-lg-3" key={"menKu3_" + index}><h4 className="menu-title"></h4></div>)
            case 2:
                return (<BrMeVertPag key={"menKu3_" + index}
                    categoList={this.data.dataObj.regCatego}
                    colorHeadLine={this.data.regBar.html_bgcolor}
                    colorTextLine={this.data.regBar.html_txcolor}
                    pDataBarra={this.data.dataObj.pDataBarra} />)
            case 3:
                return (<BrMeVertNiv key={"menKu3_" + index}
                    categoList={this.data.dataObj.regCatego}
                    colorHeadLine={this.data.regBar.html_bgcolor}
                    colorTextLine={this.data.regBar.html_txcolor}
                    pDataBarra={this.data.dataObj.pDataBarra} />)
        }
        return (<></>)
    }
    menuLogin(regBarItem, index) {
        return (<BrHeadMenu key={"menKu4_" + index}
            regMenHead={this.data.dataObj.regMenHead}
            colorLinea={this.data.regBar.html_txcolor} />)
    }
    telefono(regBarItem, index) {
        return (<div className="contact-link contact-linkpo" key={"menKu5_" + index}
            onClick={() => { window.location.href = "tel:" + regBarItem.valtext }}
            style={{
                color: this.data.regBar.html_txcolor
                , width: '39px'
                , height: '39px'
                , cursor: "pointer"
                , background: `url('/img/icon_header_phone.png')no-repeat 100% 100%`
            }}
        >
            <a href={"tel:" + regBarItem.valtext}
                style={{ color: this.data.regBar.html_txcolor, cursor: "pointer" }}
                target="_blank"
            >{/*regBarItem.valtext*/}</a>
        </div>)
    }
    whatsap(regBarItem, index) {
        return (<div className="contact-link contact-linkwa" key={"menKu6_" + index}
            style={{
                color: this.data.regBar.html_txcolor
                , cursor: "pointer"
                , background: `url('/img/icon_header_wasap.png')no-repeat 10% 10%`
                , width: '39px'
                , height: '39px'
                , top: '7px'
            }}
        >
            <a href={"https://wa.me/" + regBarItem.valtext + "?text=Me gustaría%20realizar%20una%20consulta"}
                style={{ color: this.data.regBar.html_txcolor, cursor: "pointer" }}
                target="_blank"
            >{/*regBarItem.valtext*/}</a>
        </div>)

    }
    carrito(regBarItem, index) {
        return (<div className="contact-link contact-link-nro" key={"menKu7_" + index}>
            <a title="Carrito"
                href="#offcanvas-cart"
                className="bag offcanvas-toggle"
                data-number={this.data
                    && this.data.dataObj.regTotal
                    && this.data.dataObj.regTotal[0].totcarroitem
                }>
                <i className="icon-shopping-cart"></i>
                <span style={{ color: this.data.regBar.html_txcolor }}>
                    {this.data && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totalpagarformat && ("$" + this.data.dataObj.regTotal[0].totalpagarformat)}
                </span>
            </a>
        </div>)
    }
    corazones(regBarItem, index) {
        return (<div className="contact-link contact-link-nro" key={"menKu8_" + index} >
            <a onClick={() => this.props.navigate(ApiGl.deseosRoute)}
                title="Lista de Deseos"
                className="heart offcanvas-toggle"
                data-number={this.data && this.data.dataObj.regTotal[0].totheart}>
                <i className="icon-heart"></i>
            </a>
        </div>)
    }
}

export default WithNavigate(HFrBarraMenu);